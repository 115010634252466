/**
 * Type safe delete keys from object a to get object B
 * @param big: the object containing the keys to delete
 * @param keysToDelete: keys to delete
 */
import React from 'react'

export function deleteKeys<SmallObject extends Record<string, unknown>, BigObject extends SmallObject>(
  big: BigObject,
  ...keysToDelete: (keyof BigObject)[]
): SmallObject {
  let cleanObj = {}
  Object.keys(big).forEach((key) => {
    cleanObj = !keysToDelete.includes(key) ? { ...cleanObj, [key]: big[key] } : cleanObj
  })
  return cleanObj as SmallObject
}

/**
 * @deprecated moved to pharaoh
 */
export const typedMemo: <T>(c: T) => T = React.memo
