import { FlexSpacedContainer, LegacyDialog, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'

export const PreventUpdatingContractDialog = memo<{ hide: () => void }>(function PreventUpdatingContractDialog({
  hide,
}) {
  return (
    <LegacyDialog
      title="Créez un nouveau contrat"
      style="problem"
      variant="backoffice"
      secondaryActionLabel="Fermer"
      onSecondaryAction={hide}
      onClose={hide}
    >
      <FlexSpacedContainer margin={`${spacing[60]} 0`} padding="0">
        Le changement que vous proposez nécessite un nouveau contrat. <br />
        Un avenant ne peut modifier que les détails du contrat en restant avec le même produit et une activité proche
      </FlexSpacedContainer>
    </LegacyDialog>
  )
})
