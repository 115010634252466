import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'
import { Text } from '../../components'
import { spacing } from '../../foundation'

export type HeaderProps = {
  title: string
  badge?: ReactNode
  leftButton?: ReactNode
  rightButtonGroups?: ReactNode[]
}

export const Header = memo<HeaderProps>(function Header(props: HeaderProps) {
  const { title, badge, leftButton, rightButtonGroups } = props

  return (
    <HeaderContainer>
      <MainContentContainer>
        {leftButton}
        <TitleText variant="subtitle1">{title}</TitleText>
        {badge}
      </MainContentContainer>
      {rightButtonGroups}
    </HeaderContainer>
  )
})

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${spacing[50]};
`

const MainContentContainer = styled.div`
  overflow: hidden;

  display: flex;
  flex: 1;

  align-items: center;
  gap: ${spacing[50]};
`
const TitleText = styled(Text)`
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
