import { getExclusionDescription, type Exclusion, type ExclusionDimension } from '@orus.eu/dimensions'
import { PersistentNotification, typedMemo, useLanguage } from '@orus.eu/pharaoh'

export type ExclusionDimensionFieldProps<NAME extends string, ValueDimension extends ExclusionDimension<NAME>> = {
  state: { readonly [key in NAME]?: readonly Exclusion[] | null }
  valueDimension: ValueDimension
  className?: string
}

export const ExclusionsDimensionField = typedMemo(function TextDimensionField<NAME extends string>(
  props: ExclusionDimensionFieldProps<NAME, ExclusionDimension<NAME>>,
) {
  const language = useLanguage()
  const name: NAME = props.valueDimension.name
  const exclusions = props.state[name]

  if (!exclusions || exclusions.length === 0) return <></>

  return (
    <PersistentNotification variant="warning" title="Exclusions" className={props.className}>
      <ul>
        {exclusions.map((exclusion) => (
          <li key={exclusion.name}>{getExclusionDescription(exclusion, language)}</li>
        ))}
      </ul>
    </PersistentNotification>
  )
})
