import { css } from '@emotion/react'
import styled from '@emotion/styled'

import type { SubscriptionNonDimensionalData } from '@orus.eu/backend/src/routers/pending-subscriptions'
import {
  activitiesDimension,
  displayNameByProduct,
  forbiddenMonthlyPaymentExemptionDimension,
  getSelectedProducts,
  hasAcceptedDimension,
  offerInformationDimension,
  productSelectionDimensions,
  type commitmentDimension,
  type forbiddenMonthlyPaymentDimension,
  type PartialDimensionnedState,
  type paymentRecurrenceDimension,
  type quoteDimension,
} from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import {
  Avatar,
  Button,
  colorTokens,
  spacing,
  Text,
  useAsyncCallback,
  useEnqueueToast,
  type CompoundIconName,
} from '@orus.eu/pharaoh'
import { ButtonGroup } from '@orus.eu/pharaoh/src/components/button/button-group'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { memo, useCallback, useMemo, useState } from 'react'
import { trpc } from '../../../../../client'
import { copyLinkToClipboardWithAlert } from '../../../../../lib/clipboard-util'
import { assert } from '../../../../../lib/errors'
import { usePermissions } from '../../../../../lib/use-permissions'
import { validateEmail } from '../../../../../lib/validation'
import { useBuildUrl } from '../../../../../use-build-url'
import {
  disabledReasonsTooltips,
  getDisabledReasonTooltip,
  noEmailDisabledReasonTooltip,
  noPhoneDisabledReasonTooltip,
  noQuoteDisabledReasonTooltip,
} from '../../../../organisms/share-quote-button'
import { LegacyDataPolicyAcceptanceCheckbox } from '../../../subscription-v2/elements/DataPolicyAcceptanceSubscriptionUiElementBlock'
import { getDisabledPaymentReason } from '../backoffice-pending-subscriptions/pending-subscription-payment-disabled-reasons'
import { type HighlightDimensions, type HighlightDimensionsReason } from '../use-required-dimensions-highlights'
import { PricingDimensionField } from './fields/pricing-dimension-field'
import type { useQuoteEditorState } from './quote-editor-v2-state'

export type QuoteEditorV2SidePanelState = PartialDimensionnedState<
  [
    typeof paymentRecurrenceDimension,
    typeof quoteDimension,
    typeof commitmentDimension,
    typeof forbiddenMonthlyPaymentDimension,
    typeof forbiddenMonthlyPaymentExemptionDimension,
    typeof activitiesDimension,
    typeof offerInformationDimension,
    typeof hasAcceptedDimension,
  ]
> &
  PartialDimensionnedState<typeof productSelectionDimensions>

export type QuoteEditorV2SidePanelProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: QuoteEditorV2SidePanelState
  stateBefore: QuoteEditorV2SidePanelState
  nonDimensionalDataAfter: SubscriptionNonDimensionalData
  setChanges: (changes: QuoteEditorV2SidePanelState) => void
  toggleHighlightDimensionsReason: (reason: HighlightDimensionsReason) => void
  highlightDimensionsByReason: HighlightDimensions
  isSidePanelOpen: boolean
  toggleIsSidePanelOpen: () => void
  hasQuote: boolean
  currentEmail: string | null
  currentPhone: string | null
  signatureUrl: string
  subscriptionId: string
}

export const QuoteEditorV2SidePanel = memo(function QuoteEditorV2SidePanel(props: QuoteEditorV2SidePanelProps) {
  const {
    state,
    stateBefore,
    stateAfter,
    changes,
    nonDimensionalDataAfter,
    setChanges,
    toggleHighlightDimensionsReason,
    highlightDimensionsByReason,
    isSidePanelOpen,
    toggleIsSidePanelOpen,
    hasQuote,
    currentEmail,
    currentPhone,
    signatureUrl,
    subscriptionId,
  } = props

  const { type: userType } = usePermissions()

  const quote = state.quote

  const activitiesDisplayNames = state.activities?.map((activity) => activity.displayName)
  const products = getSelectedProducts(state)
  const productsDisplayNames = products.map((product) => displayNameByProduct[product])

  const offerDisplayNames = state.offerInformation?.products.map((product) => product.name)
  const hasChanges = Object.keys(props.changes).length > 0

  const disabledPaymentReason = getDisabledPaymentReason({ changes, state: stateBefore, nonDimensionalDataAfter })

  const disabledPaymentReasonMissingFieldsNames = nonDimensionalDataAfter.missingSubscriptionDimensionsNames

  const disabledPaymentReasonMissingFieldsSet = new Set(disabledPaymentReasonMissingFieldsNames)

  const allowedMissingFieldsSet = new Set(['hasAccepted'])
  const canHideEmptySubscriptionState =
    disabledPaymentReasonMissingFieldsSet.size <= allowedMissingFieldsSet.size &&
    [...disabledPaymentReasonMissingFieldsSet].every((x) => allowedMissingFieldsSet.has(x))

  const { enqueueToast } = useEnqueueToast()

  const [sendingEmail, setSendingEmail] = useState(false)

  const brokerSendProspectEmail = useAsyncCallback(async () => {
    setSendingEmail(true)
    await trpc.subscriptions.partnerSendProspectSubscriptionEmail.mutate(subscriptionId)
    enqueueToast('Email envoyé', { variant: 'success' })
    setSendingEmail(false)
  }, [subscriptionId, enqueueToast])

  const isCurrentEmailValid = useMemo(() => currentEmail && validateEmail(currentEmail), [currentEmail])

  return (
    <SidePanelContainer>
      {isSidePanelOpen ? (
        <>
          <TitleContainer>
            <Text variant="body1Medium">Tarificateur</Text>
            <Button
              onClick={toggleIsSidePanelOpen}
              icon="arrow-right-to-line-regular"
              variant="secondary"
              size="small"
            />
          </TitleContainer>
          {quote ? (
            <>
              <PricingDimensionField
                state={state}
                beforeState={stateBefore}
                setChanges={setChanges}
                nonDimensionalDataAfter={nonDimensionalDataAfter}
              />
              <SidePanelSection>
                <Text variant="body2Medium">Partager le devis</Text>
                <ButtonGroup>
                  {userType === 'partner' ? (
                    <Button
                      variant="secondary"
                      size="small"
                      icon="envelope-regular"
                      avatarPosition="left"
                      disabled={
                        hasChanges ||
                        !props.hasQuote ||
                        !isCurrentEmailValid ||
                        sendingEmail ||
                        props.nonDimensionalDataAfter.canGenerateAgreedTermsDraft.type === 'cannot-generate'
                      }
                      isLoading={sendingEmail}
                      onClick={brokerSendProspectEmail}
                    >
                      Envoyer par mail
                    </Button>
                  ) : undefined}
                  <QuoteSharingButton
                    nonDimensionalData={props.nonDimensionalDataAfter}
                    hasChanges={hasChanges}
                    hasQuote={hasQuote}
                    hasEmail={!!currentEmail}
                    hasPhone={!!currentPhone}
                  />
                </ButtonGroup>
              </SidePanelSection>
              {canHideEmptySubscriptionState && stateAfter ? (
                <SidePanelSection>
                  <Text variant="body2Medium">Finaliser la souscription</Text>
                  <LegacyDataPolicyAcceptanceCheckbox
                    hasAccepted={stateAfter?.hasAccepted ?? false}
                    setHasAccepted={(value) => setChanges({ hasAccepted: value })}
                    type="backoffice"
                    operatingZone={checkDefinedAndNotNull(stateAfter.operatingZone)}
                  />
                  <ButtonGroup>
                    <Button
                      variant="primary"
                      size="small"
                      icon="phone-light"
                      avatarPosition="left"
                      css={css`
                        flex-grow: 1;
                      `}
                      disabled={!!disabledPaymentReason}
                      href={signatureUrl}
                      title={disabledPaymentReason || ''}
                    >
                      Finaliser au téléphone
                    </Button>
                  </ButtonGroup>
                  {nonDimensionalDataAfter.contract ? (
                    <SuccessPaymentRow>
                      <Avatar
                        variant="contained"
                        color={colorTokens['color-bg-success-primary']}
                        size="40"
                        icon="check-solid"
                      />
                      <Text variant="body2Medium" color={colorTokens['color-bg-success-primary']}>
                        Souscription finalisée
                      </Text>
                    </SuccessPaymentRow>
                  ) : (
                    <></>
                  )}
                </SidePanelSection>
              ) : (
                <EmptyState
                  icon="pen-light"
                  text="Remplissez la partie Informations pour pouvoir signer et finaliser."
                  toggleFunction={() => toggleHighlightDimensionsReason('subscription')}
                  active={!!highlightDimensionsByReason['subscription']}
                  small
                />
              )}

              <SummaryBlock
                activities={activitiesDisplayNames}
                products={productsDisplayNames}
                offers={offerDisplayNames}
              />
            </>
          ) : (
            <EmptyState
              icon="calculator-light"
              text="Remplissez la partie Tarificateur pour obtenir un tarif."
              toggleFunction={() => toggleHighlightDimensionsReason('quote')}
              active={!!highlightDimensionsByReason['quote']}
            />
          )}
        </>
      ) : (
        <Button
          onClick={toggleIsSidePanelOpen}
          icon="arrow-left-to-line-regular"
          variant="secondary"
          size="small"
          css={css`
            display: inline-flex;
            width: fit-content;
          `}
        />
      )}
    </SidePanelContainer>
  )
})

export const EmptyState = memo(function EmptyState({
  icon,
  text,
  toggleFunction,
  active,
  small = false,
}: {
  icon: CompoundIconName
  text: string
  toggleFunction: () => void
  active: boolean
  small?: boolean
}) {
  return (
    <EmptyStateContainer small={small}>
      <Avatar icon={icon} size="40" color={colorTokens['color-bg-neutral']} />
      <Text
        variant="caption"
        color={colorTokens['color-text-base-basic']}
        css={css`
          text-align: center;
        `}
      >
        {text}
      </Text>
      <Button onClick={toggleFunction} size="small">
        {active ? 'Masquer les champs requis' : 'Afficher les champs requis'}
      </Button>
    </EmptyStateContainer>
  )
})

export const SummaryBlock = memo(function SummaryBlock({
  activities,
  products,
  offers,
}: {
  activities?: string[]
  products?: string[]
  offers?: string[]
}) {
  const sections: { title: string; elements: string[] | undefined }[] = [
    { title: 'Activité', elements: activities },
    { title: 'Produit d’assurance', elements: products },
    { title: 'Offre personnalisée', elements: offers },
  ]

  return (
    <SidePanelSection>
      <Text variant="body2Medium">Récapitulatif</Text>
      <RowContainer variant="border" size="small">
        {sections.map((section, index) =>
          section.elements ? (
            <Row
              variant="descriptive"
              key={index}
              title={section.title}
              titleVariant="body2Medium"
              titleColor={colorTokens['color-text-base-main']}
              subtitleVariant="body2"
              subtitleColor={colorTokens['color-text-base-basic']}
              subtitle={
                <>
                  {section.elements.map((element) => (
                    <>
                      {element}
                      <br />
                    </>
                  ))}
                </>
              }
            />
          ) : (
            <></>
          ),
        )}
      </RowContainer>
    </SidePanelSection>
  )
})

const EmptyStateContainer = styled.div<{ small: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ small }) => (small ? 'auto' : '320px')};

  justify-content: center;
  align-items: center;

  gap: ${spacing[40]};
  padding: ${spacing[60]};

  border-radius: ${spacing[30]};

  background-color: ${colorTokens['color-bg-neutral-inverse']};
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SidePanelContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${spacing[60]};
  padding: ${spacing[60]};
`

const SidePanelSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${spacing[30]};
`

type QuoteSharingButtonProps = {
  nonDimensionalData: SubscriptionNonDimensionalData
  hasChanges: boolean
  hasQuote: boolean
  hasEmail: boolean
  hasPhone: boolean
}

const QuoteSharingButton = memo<QuoteSharingButtonProps>(function QuoteSharingButton(props) {
  const { enabled, token, tootip } = getQuoteSharingButtonConfiguration(props)
  const { enqueueToast } = useEnqueueToast()
  const { buildUrl } = useBuildUrl()

  const copyLink = useCallback(() => {
    assert(token, 'token should be defined')
    copyLinkToClipboardWithAlert(
      buildUrl({
        to: props.nonDimensionalData.hasEndorsementInProgress ? '/s/e/$token' : '/s/q/$token',
        params: { token },
      }),
    )
    enqueueToast('Lien du devis copié', { variant: 'success' })
  }, [props.nonDimensionalData.hasEndorsementInProgress, token, enqueueToast, buildUrl])

  return (
    <Button
      variant="secondary"
      size="small"
      icon="link-regular"
      avatarPosition="left"
      disabled={!enabled}
      onClick={copyLink}
      css={css`
        flex-grow: 1;
      `}
      title={tootip}
    >
      Copier le lien
    </Button>
  )
})

function getQuoteSharingButtonConfiguration({
  hasChanges,
  hasQuote,
  hasEmail,
  hasPhone,
  nonDimensionalData,
}: QuoteSharingButtonProps): {
  tootip: string
  enabled: boolean
  token: string | undefined
} {
  if (hasChanges) {
    return {
      enabled: false,
      tootip: disabledReasonsTooltips['unsaved-changes'],
      token: undefined,
    }
  }

  const { quoteSharingStatus } = nonDimensionalData

  if (quoteSharingStatus.type === 'disabled') {
    return {
      enabled: false,
      tootip: getDisabledReasonTooltip(quoteSharingStatus),
      token: undefined,
    }
  }

  const canGenerateDraftAgreedTerms = nonDimensionalData.canGenerateAgreedTermsDraft
  if (canGenerateDraftAgreedTerms.type === 'cannot-generate') {
    return {
      enabled: false,
      tootip: canGenerateDraftAgreedTerms.reason,
      token: undefined,
    }
  }

  if (!hasQuote) {
    return {
      enabled: false,
      tootip: noQuoteDisabledReasonTooltip,
      token: undefined,
    }
  }
  if (!hasEmail) {
    return {
      enabled: false,
      tootip: noEmailDisabledReasonTooltip,
      token: undefined,
    }
  }
  if (!hasPhone) {
    return {
      enabled: false,
      tootip: noPhoneDisabledReasonTooltip,
      token: undefined,
    }
  }

  return {
    enabled: true,
    tootip: 'Copier le lien à partager avec le client',
    token: quoteSharingStatus.token,
  }
}

const SuccessPaymentRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[20]};
`
