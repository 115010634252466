import type { SubscriptionNonDimensionalData } from '@orus.eu/backend/src/routers/pending-subscriptions'
import { type PartialDimensionnedState, productSelectionDimensions, quoteDimension } from '@orus.eu/dimensions'
import { useEffect, useMemo, useState } from 'react'

export type HighlightDimensionsReason = 'quote' | 'subscription'

export type HighlightDimensions = Record<HighlightDimensionsReason, string[] | undefined>

export function useRequiredDimensionsHighlights({
  state,
  cleanTabsMissingDimensions,
  nonDimensionalDataAfter,
}: {
  state: PartialDimensionnedState<typeof productSelectionDimensions> & PartialDimensionnedState<[typeof quoteDimension]>
  subscriptionId: string
  cleanTabsMissingDimensions: () => void
  nonDimensionalDataAfter?: SubscriptionNonDimensionalData
}): {
  toggleHighlightDimensionsReason: (reason: HighlightDimensionsReason) => void
  highlightDimensionsByReason: HighlightDimensions
  highlightDimensionsNames: string[]
  requiredDimensionsNames: string[]
} {
  const {
    requiredQuoteDimensionsNames,
    requiredSubscriptionDimensionsNames,
    missingQuoteDimensionsNames,
    missingSubscriptionDimensionsNames,
  } = nonDimensionalDataAfter || {}

  const [activeHighlightDimensionsReasons, setActiveHighlightDimensionsReasons] = useState<{
    [key in HighlightDimensionsReason]: boolean
  }>({
    quote: false,
    subscription: false,
  })

  const toggleHighlightDimensionsReason = (reason: HighlightDimensionsReason) => {
    cleanTabsMissingDimensions()
    setActiveHighlightDimensionsReasons((previous) => ({
      ...previous,
      [reason]: !previous[reason],
    }))
  }

  const highlightDimensionsByReason = useMemo(
    () => ({
      quote: activeHighlightDimensionsReasons.quote ? missingQuoteDimensionsNames : undefined,
      subscription: activeHighlightDimensionsReasons.subscription ? missingSubscriptionDimensionsNames : undefined,
    }),
    [missingQuoteDimensionsNames, missingSubscriptionDimensionsNames, activeHighlightDimensionsReasons],
  )

  const requiredDimensionsNames =
    (state.quote ? requiredSubscriptionDimensionsNames : requiredQuoteDimensionsNames) || []

  const highlightDimensionsNames = useMemo(() => {
    return Object.values(highlightDimensionsByReason).flatMap((dimensions) => dimensions ?? [])
  }, [highlightDimensionsByReason])

  useEffect(() => {
    if (state.quote) {
      setActiveHighlightDimensionsReasons((previous) => ({
        ...previous,
        quote: false,
      }))
    }
  }, [state.quote, setActiveHighlightDimensionsReasons])

  return {
    toggleHighlightDimensionsReason,
    highlightDimensionsNames,
    highlightDimensionsByReason,
    requiredDimensionsNames,
  }
}
