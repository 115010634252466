import { FlexSpacedContainer, LegacyDialog, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'

export const UnsavedChangesDialog = memo<{ reset: () => void; proceed: () => void }>(function UnsavedChangesDialog({
  reset,
  proceed,
}) {
  return (
    <LegacyDialog
      title="Êtes-vous sûr de vouloir quitter ?"
      style="problem"
      variant="backoffice"
      submitLabel="Annuler"
      onSubmit={reset}
      secondaryActionLabel="Quitter"
      onSecondaryAction={proceed}
      onClose={proceed}
    >
      <FlexSpacedContainer margin={`${spacing[60]} 0`} padding="0">
        Vous n’avez pas encore sauvegardé vos changements. Si vous quittez maintenant, toutes les modifications seront
        perdues. Voulez-vous continuer sans sauvegarder ?
      </FlexSpacedContainer>
    </LegacyDialog>
  )
})
