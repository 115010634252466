export const permissions = [
  'activities.read',
  'activities.update',
  'claims.read',
  'contracts.sign',
  'contracts.read',
  'contracts.update',
  'endorsement.create',
  'internalTools.read',
  'invoices.configuration',
  'invoices.create',
  'invoices.pay',
  'invoices.read',
  'job.runEphemeral',
  'log.send',
  'med.read',
  'notes.create',
  'notifications-configuration.read',
  'notifications-configuration.write',
  'organization.edit',
  'organization.read',
  'organization.search',
  'organization.view',
  'paymentAccount.read',
  'paymentAccount.update',
  'pharaoh.read',
  'rcda.validateDocuments',
  'report.process',
  'roles.read',
  'selfOnBoarding.create',
  'session.access',
  'session.read',
  'session.impersonate',
  'stripe.create',
  'stripe.read',
  'search',
  'subscription.allowForbiddenMonthlyPaymentExemption',
  'subscription.continue',
  'subscription.changeOwner',
  'subscription.create',
  'subscription.downloadFile',
  'subscription.read',
  'subscription.readHistory',
  'subscription.shareLink',
  'subscription.uploadFile',
  'tech.configurations',
  'tracking.read',
  'tracking.send',
  'users.read',
  'users.update',
] as const

export type Permission = (typeof permissions)[number]

export function isPermission(value: string): value is Permission {
  return permissions.includes(value as Permission)
}

export const permissionDocumentation: Record<Permission, string> = {
  'activities.read': 'Récupérer les informations des activités',
  'activities.update': 'Mettre à jour les activités',
  'claims.read': 'Récupérer les informations de contact en cas de sinistre',
  'contracts.sign': 'Signer les contrats et enregistrer le règlement',
  'contracts.read': 'Récupérer des informations sur les contrats',
  'contracts.update': 'Modifier les contrats',
  'endorsement.create': 'Créer des avenants',
  'internalTools.read': 'Voir le bouton front et hubspot',
  'invoices.configuration': 'Modifier la configuration de la facturation',
  'invoices.create': 'Créer des factures',
  'invoices.pay': 'Payer les factures',
  'invoices.read': 'Récupérer les informations sur les factures',
  'job.runEphemeral': 'Lancer des jobs éphémères',
  'log.send': 'Envoyer des logs au serveur depuis le client',
  'med.read': 'Créer des mises en demeure',
  'notes.create': 'Gérer les notes sur tous les objets (lecture, modification, suppression)',
  'notifications-configuration.read': 'Récupération de la configuration des notifications',
  'notifications-configuration.write': 'Modification de la configuration des notifications',
  'organization.edit': 'Modification de la liste des organisations et des membres',
  'organization.search': 'Recherche des informations sur les organisations',
  'organization.read': 'Lire la liste des organisations et des membres',
  'organization.view': 'Peut voir des informations sur une organization',
  'paymentAccount.read': 'TBD',
  'paymentAccount.update': 'TBD',
  'pharaoh.read': 'See the pharaoh page',
  'rcda.validateDocuments': 'Validation des documents d’une souscription avec RCDA',
  'report.process': 'Lancer la génération des rapports et recevoir les rapports',
  'roles.read': 'Récupérer la liste des rôles et leur définition',
  'selfOnBoarding.create': 'Créer une souscription en self-onboarding',
  'session.access':
    'Récupérer les information de sa session, et faire les actions de bases nécessaires au fonctionnement du parcours de souscription',
  'session.read': 'Récupérer les informations détaillées des sessions',
  'session.impersonate':
    'Créer une session pour un autre utilisateur, pour afficher des informations et effectuer des actions en son nom',
  'stripe.create': 'Créer des objets avec l’API Stripe',
  'stripe.read': 'Récupérer des objets avec l’API Stripe',
  search: 'Utiliser la recherche des contrats, souscriptions et utilisateurs',
  'subscription.allowForbiddenMonthlyPaymentExemption': 'Permettre une dérogation de paiement mensuel',
  'subscription.continue': 'Continuer une souscription en cours',
  'subscription.changeOwner': 'Changer le propriétaire d’une souscription',
  'subscription.create': 'Créer des souscriptions',
  'subscription.downloadFile': 'Télécharger les fichiers liés à une souscription',
  'subscription.read': 'Réupérer des informations sur les souscriptions',
  'subscription.readHistory': 'Réupérer l’historique détaillé des souscriptions',
  'subscription.shareLink': 'Créer des liens de partage de souscription',
  'subscription.uploadFile': 'Uploader des fichiers liés à une souscription',
  'tech.configurations': 'Configurer les paramètres techniques de l’application',
  'tracking.read': 'Récupérer des informations de documetation du tracking',
  'tracking.send': 'Envoyer des message de tracking',
  'users.read': 'Récupérer des informations sur les utilisateurs',
  'users.update': 'Mettre à jour des informations sur les utilisateurs',
}
