import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { amountToString, gt, substractAmounts, zeroAmount, type Amount } from '@orus.eu/amount'
import type { SubscriptionNonDimensionalData } from '@orus.eu/backend/src/routers/pending-subscriptions'
import { nbsp } from '@orus.eu/char'
import {
  commitmentDimension,
  forbiddenMonthlyPaymentDimension,
  forbiddenMonthlyPaymentExemptionDimension,
  getCummulatedMonthlyTotalPremiumPerYear,
  getYearlyTotalPremiumSplitPerMonth,
  quoteDimension,
  type AggregatedQuote,
  type PartialDimensionnedState,
  type paymentRecurrenceDimension,
} from '@orus.eu/dimensions'
import {
  Avatar,
  Badge,
  Chip,
  PersistentNotification,
  Text,
  Tooltip,
  colorTokens,
  spacing,
  typedMemo,
  useTranslate,
} from '@orus.eu/pharaoh'
import { Row, RowContainer, type RowProps } from '@orus.eu/pharaoh/src/components/rows'
import { forwardRef, memo, useCallback } from 'react'
import { usePermissions } from '../../../../../../lib/use-permissions'
import { CheckboxDimensionField } from './checkbox-dimension-field'

export type PricingFieldState = PartialDimensionnedState<
  [
    typeof paymentRecurrenceDimension,
    typeof quoteDimension,
    typeof commitmentDimension,
    typeof forbiddenMonthlyPaymentDimension,
    typeof forbiddenMonthlyPaymentExemptionDimension,
  ]
>

export type PricingDimensionFieldProps = {
  beforeState: PricingFieldState
  state: PricingFieldState
  setChanges: (changes: PricingFieldState) => void
  nonDimensionalDataAfter: SubscriptionNonDimensionalData
}

export const PricingDimensionField = typedMemo(function PricingDimensionField(props: PricingDimensionFieldProps) {
  const translate = useTranslate()
  const { beforeState, state, setChanges, nonDimensionalDataAfter } = props
  const { permissions } = usePermissions()
  const allowForbiddenMonthlyPaymentExemption = permissions.includes(
    'subscription.allowForbiddenMonthlyPaymentExemption',
  )

  let previousQuote: AggregatedQuote | undefined
  if (nonDimensionalDataAfter.contract) {
    previousQuote = nonDimensionalDataAfter.contract.latestVersion.quote.quote
  } else {
    previousQuote = beforeState.quote ?? undefined
  }

  const paymentRecurrence = state.paymentRecurrence ?? 'monthly'

  const handlePaymentRecurrenceClick = useCallback(
    (type: 'yearly' | 'monthly') => {
      setChanges({ paymentRecurrence: type })
    },
    [setChanges],
  )

  const quote = state.quote

  return quote ? (
    <Container>
      {state.forbiddenMonthlyPayment || state.forbiddenMonthlyPaymentExemption ? (
        <>
          <PersistentNotification variant="warning">
            Ce devis ne peut pas être payé mensuellement, hors dérogation.
          </PersistentNotification>
          {allowForbiddenMonthlyPaymentExemption ? (
            <CheckboxDimensionField
              state={state}
              valueDimension={forbiddenMonthlyPaymentExemptionDimension}
              setChanges={setChanges}
            />
          ) : null}
        </>
      ) : null}

      <SubContainer>
        <RowContainer radio variant="spacing" size="medium">
          <PriceBlock
            type="yearly"
            selected={paymentRecurrence === 'yearly'}
            price={quote.yearlyTotalPremium}
            priceOppositeRecurrence={getYearlyTotalPremiumSplitPerMonth(quote)}
            oldPrice={previousQuote?.yearlyTotalPremium}
            onClick={handlePaymentRecurrenceClick}
          />
          <PriceBlock
            type="monthly"
            selected={paymentRecurrence === 'monthly'}
            disabled={!!state.forbiddenMonthlyPayment}
            price={quote.subsequentMonthsTotalPremium}
            priceOppositeRecurrence={getCummulatedMonthlyTotalPremiumPerYear(quote)}
            oldPrice={previousQuote?.subsequentMonthsTotalPremium}
            onClick={handlePaymentRecurrenceClick}
          />
        </RowContainer>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Text variant="caption" color={colorTokens['color-text-base-basic']}>
            {translate(`per_time_unit_taxes_included_${paymentRecurrence}`)}
            {nbsp}
          </Text>
          <Tooltip
            title={
              <>
                1er paiement de {amountToString(quote.firstPaymentAmount, { addCurrency: true })}
                {gt(quote.terrorismTax, zeroAmount) ? (
                  <>
                    <br />
                    Taxe attentat de {amountToString(quote.terrorismTax, { addCurrency: true })} incluse
                  </>
                ) : (
                  <></>
                )}
              </>
            }
          >
            <Avatar icon="circle-info-regular" size="10" />
          </Tooltip>
        </div>

        {state.commitment === 'yearly' && state.paymentRecurrence === 'monthly' ? (
          <PersistentNotification variant="info" title="Pas de résiliation à tout moment">
            Le client paiera bien mensuellement mais ne pourra pas résillier à tout moment, à cause de contraintes du
            porteur de risque.
          </PersistentNotification>
        ) : (
          <></>
        )}
      </SubContainer>
    </Container>
  ) : (
    <></>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[30]};
`

type PriceBlockProps = {
  type: 'yearly' | 'monthly'
  selected: boolean
  disabled?: boolean
  /** The price in the selected recurrence */
  price: Amount
  /** The price annualized if monthly or mensualized if yearly */
  priceOppositeRecurrence: Amount
  /** The price before the changes */
  oldPrice?: Amount

  onClick: (type: 'yearly' | 'monthly') => void
}

const PriceBlock = memo(
  forwardRef<RowProps, PriceBlockProps>(function PriceBlock(props) {
    const { type, disabled, selected, price, priceOppositeRecurrence, oldPrice, onClick, ...otherProps } = props

    const priceDifference = substractAmounts(price, oldPrice ?? price)
    const absolutePriceDifference = { __encodedAmount: Math.abs(priceDifference.__encodedAmount) }
    const formattedPriceDifference = `${priceDifference.__encodedAmount >= 0 ? '+' : '-'} ${amountToString(
      absolutePriceDifference,
      { addCurrency: true },
    )}`

    const handleClick = useCallback(() => {
      onClick(type)
    }, [onClick, type])

    return (
      <Row
        disabled={disabled}
        selected={selected}
        onClick={handleClick}
        title={amountToString(price, { addCurrency: true })}
        subtitle={amountToString(priceOppositeRecurrence, { addCurrency: true })}
        chip={
          priceDifference.__encodedAmount !== 0 ? (
            <Chip size="small" variant={priceDifference.__encodedAmount > 0 ? 'danger' : 'success'}>
              {formattedPriceDifference}
            </Chip>
          ) : undefined
        }
        badge={<Badge dark={type === 'yearly'}>{type === 'yearly' ? 'Annuel' : 'Mensuel'}</Badge>}
        {...otherProps}
      />
    )
  }),
)
