import type { BooleanDimension } from '@orus.eu/dimensions'
import { CheckboxContainer, typedMemo, useLanguage } from '@orus.eu/pharaoh'
import { useCallback } from 'react'

export type CheckboxDimensionFieldProps<NAME extends string, ValueDimension extends BooleanDimension<NAME>> = {
  state: { readonly [key in NAME]?: boolean | null }
  valueDimension: ValueDimension
  setChanges: (changes: { readonly [key in NAME]?: boolean | null }) => void
}

export const CheckboxDimensionField = typedMemo(function CheckboxDimensionField<NAME extends string>(
  props: CheckboxDimensionFieldProps<NAME, BooleanDimension<NAME>>,
) {
  const language = useLanguage()
  const name: NAME = props.valueDimension.name
  const checked = !!props.state[name]
  const { valueDimension, setChanges } = props
  const handleChange = useCallback(
    (newValue: boolean | undefined) => {
      const changes: { readonly [key in NAME]?: boolean } = {}
      valueDimension.setUpdateFieldValue(changes, newValue == undefined ? null : newValue)
      setChanges(changes)
    },
    [valueDimension, setChanges],
  )
  return (
    <CheckboxContainer checked={checked} onChange={handleChange} size="small">
      {props.valueDimension.displayNames[language]}
    </CheckboxContainer>
  )
})
